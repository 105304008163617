import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  IconButton,
  Hidden
  //  Tooltip
} from "@mui/material";

import {
  EditIcon,
  ViewIcon,
  MbsIcon,
  TrafficIcon,
  OrderBuyingStatusesIcon,
  CopyIcon,
  ResumeIcon,
  SignatureIcon
  // SignatureStatusIcon
} from "../../../assets/styles/icons";

import {
  COLOR_LINKWATER,
  COLOR_BLACK,
  TRAFFIC_PAGE
  // COLOR_WHITE
} from "../../../configurations/appConstants";
import { selectInsertionIsEditing } from "../../../business/models/Insertion/insertionSelectors";
import {
  canViewTrafficDashboardPage,
  selectCanViewCopyPlan,
  canEditCampaign
} from "../../../business/selectors/userProfileSelectors";
import { doesPlanHaveOrdersReadyForTraffic } from "../../../business/models/Plan/planSelectors";
import { getCampaignData } from "../../../business/models/Campaign/campaignSelectors";
import {
  openPlanExportDialog,
  openOrderBuyingStatusesDialog,
  openPlanFormPage,
  openSignatureStatusDialog
} from "../../../business/actions/campaignDashboardActions";
import {
  initCopyPlan,
  setPlanForMbs,
  setFlightForMbs
} from "../../../business/models/Plan/planActions";
import { toggleMbsConfirmPopup } from "../../../business/actions/applicationActions";
import { classes, Grid, Badge } from "../styles/planPanelFold";

const PlanPanelFoldActions = ({
  buttonRef,
  open,
  id,
  allowDownload,
  showMbsButton,
  flightId,
  hasUnreadTrafficPlanRemarks,
  setOpen,
  isInCopyProcess,
  copyProcessId
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { agency, planId: urlPlanId } = useParams();
  const allowEdit = !useSelector(selectInsertionIsEditing);
  const allowTraffic = useSelector(canViewTrafficDashboardPage);
  const planHasOrderForTraffic = useSelector(doesPlanHaveOrdersReadyForTraffic);
  const { isDebtorActive } = useSelector(getCampaignData) || false;
  const allowCopyPlan = useSelector(selectCanViewCopyPlan) && isDebtorActive;
  const disableAction = !useSelector(canEditCampaign);

  const handleOpenOrderBuyingStatusesDialog = () =>
    dispatch(openOrderBuyingStatusesDialog());

  const handleDownloadDialog = () => {
    dispatch(openPlanExportDialog());
  };

  const handleSignatureStatusDialog = () => {
    dispatch(openSignatureStatusDialog());
  };

  const handleResume = e => {
    e.stopPropagation();

    navigate(`/${agency}/copy-plan-wizard/${copyProcessId}`);
  };

  const handleToggle = () => {
    buttonRef.current.focus();
    setOpen(!open);
  };

  const handleTrafficClick = e => {
    e.stopPropagation();
    navigate(`/${agency}/${TRAFFIC_PAGE}/${id}`);
  };

  const handleCopyPlan = e => {
    e.stopPropagation();

    dispatch(initCopyPlan(urlPlanId)).then(processId => {
      if (processId) {
        navigate(`/${agency}/copy-plan-wizard/${processId}`);
      }
    });
  };

  const handleMbs = () => {
    dispatch(setPlanForMbs(id));
    dispatch(setFlightForMbs(flightId));
    dispatch(toggleMbsConfirmPopup());
  };

  const handleEdit = () => {
    dispatch(openPlanFormPage());
  };

  return (
    <Grid container>
      <Grid item>
        <Hidden xsUp={isInCopyProcess}>
          <IconButton
            name={`p_edit_${id}`}
            id={`p_edit_${id}`}
            onClick={handleEdit}
            className={classes.iconButton}
            disabled={!allowEdit || disableAction}
          >
            <EditIcon disabled={disableAction} />
          </IconButton>
        </Hidden>
      </Grid>
      <Grid item>
        <IconButton
          name={`p_view_${id}`}
          id={`p_view_${id}`}
          onClick={handleToggle}
          ref={buttonRef}
          className={classes.iconButton}
        >
          <ViewIcon color={COLOR_BLACK} />
        </IconButton>
      </Grid>
      <Grid item>
        {allowTraffic && planHasOrderForTraffic && (
          <Hidden xsUp={isInCopyProcess}>
            <IconButton
              name={`p_traffic_${id}`}
              id={`p_traffic_${id}`}
              onClick={handleTrafficClick}
              className={classes.iconButton}
              disabled={disableAction}
            >
              <Badge
                variant="dot"
                invisible={!hasUnreadTrafficPlanRemarks}
                color="secondary"
              >
                <TrafficIcon
                  color={disableAction ? COLOR_LINKWATER : COLOR_BLACK}
                />
              </Badge>
            </IconButton>
          </Hidden>
        )}
      </Grid>
      <Grid item>
        <Hidden xsUp={isInCopyProcess}>
          <IconButton
            name={`p_buyingStatus_${id}`}
            id={`p_buyingStatus_${id}`}
            onClick={handleOpenOrderBuyingStatusesDialog}
            className={classes.iconButton}
            disabled={disableAction}
          >
            <OrderBuyingStatusesIcon color={COLOR_BLACK} />
          </IconButton>
        </Hidden>
      </Grid>
      {allowCopyPlan && (
        <Grid item>
          <Hidden xsUp={isInCopyProcess}>
            <IconButton
              name={`p_copy_${id}`}
              id={`p_copy_${id}`}
              onClick={handleCopyPlan}
              className={classes.iconButton}
              disabled={disableAction}
            >
              <CopyIcon color={COLOR_BLACK} />
            </IconButton>
          </Hidden>
        </Grid>
      )}
      {showMbsButton && (
        <Grid item>
          <Hidden xsUp={isInCopyProcess}>
            <IconButton
              name={`p_mbs_${id}`}
              id={`p_mbs_${id}`}
              disabled={disableAction}
              onClick={handleMbs}
              className={classes.iconButton}
            >
              <MbsIcon disabled={disableAction} color={COLOR_BLACK} />
            </IconButton>
          </Hidden>
        </Grid>
      )}
      <Grid item>
        {allowDownload && (
          <Hidden xsUp={isInCopyProcess}>
            {/* <Tooltip
              title={
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton
                      name={`p_signature_status_${id}`}
                      id={`p_signature_status__${id}`}
                      onClick={handleSignatureStatusDialog}
                      className={classes.iconButton}
                      disabled={disableAction}
                    >
                      <SignatureStatusIcon
                        color={disableAction ? COLOR_LINKWATER : COLOR_WHITE}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>Click icon for signature status</Grid>
                </Grid>
              }
            > */}
            <IconButton
              name={`p_download_${id}`}
              id={`p_download__${id}`}
              onClick={handleDownloadDialog}
              className={classes.iconButton}
              disabled={disableAction}
            >
              <SignatureIcon
                color={disableAction ? COLOR_LINKWATER : COLOR_BLACK}
              />
            </IconButton>
            {/* </Tooltip> */}
          </Hidden>
        )}
      </Grid>
      <Grid item>
        <Hidden xsUp={!isInCopyProcess}>
          <IconButton
            name={`p_resume_copy_process_${id}`}
            id={`p_resume_copy_process_${id}`}
            onClick={handleResume}
            className={classes.iconButton}
            disabled={disableAction}
          >
            <ResumeIcon color={disableAction ? COLOR_LINKWATER : COLOR_BLACK} />
          </IconButton>
        </Hidden>
      </Grid>
    </Grid>
  );
};

PlanPanelFoldActions.propTypes = {};

export default PlanPanelFoldActions;
