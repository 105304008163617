import { Model, attr, fk } from "redux-orm";
import set from "lodash/set";
import omit from "lodash/omit";
import forEach from "lodash/forEach";

import {
  validateModel,
  validateProperty,
  doesModelHaveErrors
} from "../common/utils/validationUtils";

import {
  MANDATORY_RULE,
  DATE_RULE,
  ISO8859_RULE,
  NUMBER_OF_CHARACTERS_RULE,
  MANDATORY_MESSAGE,
  DATE_MESSAGE,
  ISO8859_MESSAGE,
  DECIMAL_RULE,
  DECIMAL_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_50,
  NUMBER_OF_CHARACTERS_MESSAGE_500,
  VALIDATE_EXCHANGE_RATE,
  VALIDATE_EXCHANGE_RATE_MESSAGE,
  NUMBER_OF_CHARACTERS_MESSAGE_200
} from "../../constants/validationConstants";

import {
  CAMPAIGN_MODEL_NAME,
  CAMPAIGN_MODEL_ID
} from "../Campaign/campaignConstants";
import { PLAN_MODEL_NAME, PLAN_MODEL_ID } from "./planConstants";
import {
  PLAN_EXTENSION_PROPERTY,
  PLAN_EXTENSION_MODEL_NAME
} from "../PlanExtension/planExtensionConstants";
import { ORDER_MODEL_NAME, ORDERS_PROPERTY } from "../Order/orderConstants";

import {
  GET_MEDIA_TYPES,
  GET_AGREEMENTS,
  GET_AGREEMENTDETAILS,
  GET_CURRENCYSUPPLIERS,
  GET_PLANNER_USERS,
  GET_PROJECTS
} from "../../../configurations/apiUrls";

import { getModelClassByType } from "../common/utils/modelUtils";
import { EMPTY_STRING } from "../../../configurations/appConstants";
import { currencies } from "../../constants/businessConstants";

class Plan extends Model {
  static get fields() {
    return {
      id: attr(),
      planId: attr(),
      marathonPlanId: attr(),
      planName: attr(),
      mediaTypeId: attr(),
      planPoNumber: attr(),
      planRemarks: attr(),
      planAgreementId: attr(),
      currencySupplierId: attr(),
      plannerId: attr(),
      exchangeRate: attr(),
      exchangeRateClient: attr(),
      currencyClient: attr(),
      invoiceDate: attr(),
      totalBudget: attr(),
      sendToMbs: attr(),
      flightId: attr(),
      isActual: attr(),
      copyProcessId: attr(),
      isInCopyProcess: attr(),
      marathonProjectId: attr(),
      inActiveProjects: attr(),
      invoiceHeader: attr(),

      campaign: fk(CAMPAIGN_MODEL_NAME),

      // calculations
      orderAmountPercentage: attr(),
      orderTotalAmount: attr(),
      clientTotalCost: attr(),
      clientTotalCostEur: attr()
    };
  }

  static get defaultAttributes() {
    return {
      currencySupplierId: "EUR",
      exchangeRate: 1,
      currencyClient: "EUR",
      exchangeRateClient: 1,
      isActual: false,
      inActiveProjects: false
    };
  }

  static get inheritConfiguration() {
    return {
      planPoNumber: "poNumber",
      planAgreementId: "agreementId",
      marathonProjectId: "defaultMarathonProjectId"
    };
  }

  static parse(planData) {
    const planDataTransform = {
      ...planData,
      isInCopyProcess: !!planData.copyProcessId
    };

    if (planDataTransform[PLAN_MODEL_ID]) {
      set(planDataTransform, "id", planData[PLAN_MODEL_ID]);
    }

    if (planDataTransform[CAMPAIGN_MODEL_ID]) {
      set(planDataTransform, "campaign", planDataTransform[CAMPAIGN_MODEL_ID]);
    }

    // Parse Plan Extension data
    const planExtensionProperty = PLAN_EXTENSION_PROPERTY;
    const PlanExtensionModelClass = getModelClassByType(
      this.session,
      PLAN_EXTENSION_MODEL_NAME
    );

    forEach(planDataTransform[planExtensionProperty], entry => {
      PlanExtensionModelClass.parse(entry);
    });

    let parsedData = omit(planDataTransform, planExtensionProperty);

    // Parse Order data
    const childProperty = ORDERS_PROPERTY;
    const ChildModelClass = getModelClassByType(this.session, ORDER_MODEL_NAME);

    forEach(planDataTransform[childProperty], entry => {
      ChildModelClass.parse(entry);
    });

    parsedData = omit(parsedData, childProperty);

    return this.upsert(parsedData);
  }

  static updateModel(planData) {
    if (planData[PLAN_MODEL_ID]) {
      set(planData, "id", planData[PLAN_MODEL_ID]);
    }

    if (planData[CAMPAIGN_MODEL_ID]) {
      set(planData, "campaign", planData[CAMPAIGN_MODEL_ID]);
    }

    // Parse Plan Extension data
    const planExtensionProperty = PLAN_EXTENSION_PROPERTY;
    const PlanExtensionModelClass = getModelClassByType(
      this.session,
      PLAN_EXTENSION_MODEL_NAME
    );

    forEach(planData[planExtensionProperty], entry => {
      PlanExtensionModelClass.updateModel(entry);
    });

    let parsedData = omit(planData, planExtensionProperty);

    // Parse Order data
    const childProperty = ORDERS_PROPERTY;
    const ChildModelClass = getModelClassByType(this.session, ORDER_MODEL_NAME);

    forEach(planData[childProperty], entry => {
      ChildModelClass.updateModel(entry);
    });

    parsedData = omit(parsedData, childProperty);

    return this.withId(planData.id).update(parsedData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...Plan.defaultAttributes,
      ...newAttributes
    };
    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherPlan) {
    this.update(otherPlan.ref);
  }

  static get validation() {
    return {
      planName: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      planAgreementId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      mediaTypeId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      invoiceDate: [
        {
          rule: DATE_RULE,
          message: DATE_MESSAGE
        }
      ],
      totalBudget: [
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      currencyClient: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      currencySupplierId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      exchangeRate: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: VALIDATE_EXCHANGE_RATE,
          message: VALIDATE_EXCHANGE_RATE_MESSAGE,
          options: {
            currency: EMPTY_STRING,
            baselineCurrency: currencies.EUR
          },
          validateField: "currencySupplierId"
        }
      ],
      exchangeRateClient: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        },
        {
          rule: DECIMAL_RULE,
          message: DECIMAL_MESSAGE
        },
        {
          rule: VALIDATE_EXCHANGE_RATE,
          message: VALIDATE_EXCHANGE_RATE_MESSAGE,
          options: {
            currency: EMPTY_STRING,
            baselineCurrency: currencies.EUR
          },
          validateField: "currencyClient"
        }
      ],
      planPoNumber: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_50,
          options: {
            min: 0,
            max: 50
          }
        }
      ],
      planRemarks: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_500,
          options: {
            min: 0,
            max: 500
          }
        }
      ],
      plannerId: [
        {
          rule: MANDATORY_RULE,
          message: MANDATORY_MESSAGE
        }
      ],
      invoiceHeader: [
        {
          rule: ISO8859_RULE,
          message: ISO8859_MESSAGE
        },
        {
          rule: NUMBER_OF_CHARACTERS_RULE,
          message: NUMBER_OF_CHARACTERS_MESSAGE_200,
          options: {
            min: 0,
            max: 200
          }
        }
      ]
    };
  }

  static get calculations() {
    return {
      orderTotalAmount: {
        calcMethod: "sumOrderNetTotal",
        calcParams: ["netTotal"]
      },
      orderAmountPercentage: {
        calcMethod: "orderAmountPercentageOnPlan",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee",
          "totalBudget",
          "currencySupplierId",
          "exchangeRate"
        ]
      },
      clientTotalCost: {
        calcMethod: "sumtotalcostclient",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee",
          "currencySupplierId"
        ]
      },
      clientTotalCostEur: {
        calcMethod: "sumtotalcostclienteur",
        calcParams: [
          "feePercentage",
          "agencyDiscountAmount",
          "circulationBase",
          "netTotal",
          "surcharge1Amount",
          "surcharge1Commission",
          "surcharge1Fee",
          "surcharge2Amount",
          "surcharge2Commission",
          "surcharge2Fee",
          "currencySupplierId",
          "exchangeRate"
        ]
      }
    };
  }

  validate() {
    return validateModel(Plan.validation, this.toJSON());
  }

  validateByProperty(name) {
    const updatedRule = Plan.validation[name]?.map(obj =>
      obj.rule === VALIDATE_EXCHANGE_RATE
        ? {
            ...obj,
            options: {
              currency: this.ref[obj.validateField],
              baselineCurrency: obj.options.baselineCurrency
            }
          }
        : obj
    );
    return validateProperty(updatedRule, this.ref[name]);
  }

  hasErrors() {
    return doesModelHaveErrors(Plan.validation, this.toJSON());
  }

  static get apiConfiguration() {
    return {
      mediaTypeId: {
        url: GET_MEDIA_TYPES
      },
      planAgreementId: {
        url: GET_AGREEMENTS,
        urlParams: ["debtorId"],
        urlRequiredParams: ["debtorId"]
      },
      plannerId: {
        url: GET_PLANNER_USERS
      },
      currencyClient: {
        url: GET_AGREEMENTDETAILS,
        urlParams: ["planAgreementId"],
        urlRequiredParams: ["planAgreementId"],
        path: "currency"
      },
      currencySupplierId: {
        url: GET_CURRENCYSUPPLIERS
      },
      marathonProjectId: {
        url: GET_PROJECTS,
        urlParams: ["campaignId"],
        urlRequiredParams: ["campaignId"]
      }
    };
  }

  static get disabledFields() {
    return {
      mediaTypeId: [
        "doesPlanHaveOrders",
        "isInProcessPlanCopy",
        "disabledByInactiveDebtor"
      ],
      marathonPlanId: ["disabledByDefault", "disabledByInactiveDebtor"],
      currencyClient: ["disabledByDefault", "disabledByInactiveDebtor"],
      currencySupplierId: [
        "doesPlanHaveInvoicedInsertions",
        "doesPlanHaveOrders",
        "disabledByInactiveDebtor"
      ],
      exchangeRate: [
        "doesPlanHaveInvoicedInsertions",
        "isCurrencySupplierEuro",
        "disabledByInactiveDebtor"
      ],
      exchangeRateClient: [
        "doesPlanHaveInvoicedInsertions",
        "isCurrencyClientEuro",
        "disabledByInactiveDebtor"
      ],
      planName: ["disabledByInactiveDebtor"],
      planAgreementId: ["disabledByInactiveDebtor"],
      totalBudget: ["disabledByInactiveDebtor"],
      invoiceDate: ["disabledByInactiveDebtor"],
      planPoNumber: ["disabledByInactiveDebtor"],
      plannerId: ["disabledByInactiveDebtor"],
      planRemarks: ["disabledByInactiveDebtor"],
      marathonProjectId: ["disabledByInactiveDebtor"]
    };
  }

  static get dependentFields() {
    return {
      exchangeRate: "currencySupplierId",
      exchangeRateClient: "currencyClient"
    };
  }
}

Plan.modelName = PLAN_MODEL_NAME;

export default Plan;
