import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogContentText
} from "@mui/material";

const paperProps = { square: true };

const InvoiceHeaderDialog = props => {
  const { open, onOk } = props;

  return (
    <Dialog open={open} PaperProps={paperProps} maxWidth="md">
      <DialogContent>
        <DialogContentText noWrap={false}>
          This field can be used for adding information to client invoice but
          only if the invoice template is set up for it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={onOk} color="secondary">
            Ok
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceHeaderDialog;
