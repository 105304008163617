import React from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  Typography,
  Toolbar
} from "@mui/material";

import Search from "./Search";
import { EditIcon, ProfileIcon } from "../../../assets/styles/icons";
import { PLACEHOLDER } from "../../../configurations/appConstants";
import { trimString } from "../../../business/models/common/utils/clientUtils";
import { Switch } from "../../../assets/styles/switchToggle";

const UserManagementList = props => {
  const {
    users,
    onEditClick,
    onProfileClick,
    searchProperty,
    searchText,
    onInputChange,
    onChange,
    isActiveUserSwitch,
    handleToggleSwitch
  } = props;
  const color = isActiveUserSwitch ? "primary" : "default";
  return (
    <Paper elevation={0}>
      <Toolbar>
        <Typography align="left">Show all active users</Typography>
        <Switch
          id="um_show_activeUsers"
          name="um_show_activeUsers"
          onClick={handleToggleSwitch}
          checked={isActiveUserSwitch}
          color={color}
        />
        <Typography align="left">No of Users: {users.length}</Typography>
        <Search
          searchProperty={searchProperty}
          searchText={searchText}
          onInputChange={onInputChange}
          onChange={onChange}
        />
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">First Name</TableCell>
            <TableCell align="left">Username</TableCell>
            <TableCell align="right">Marathon User</TableCell>
            <TableCell align="right">Company</TableCell>
            <TableCell align="right">Is Admin</TableCell>
            <TableCell align="right">Is Agent</TableCell>
            <TableCell align="right">Agent For</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Is Active</TableCell>
            <TableCell align="center" />
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(row => (
            <TableRow key={row.userId}>
              <TableCell align="left">
                {PLACEHOLDER && trimString(row.lastName, 25)}
              </TableCell>
              <TableCell align="left">
                {PLACEHOLDER && trimString(row.firstName, 25)}
              </TableCell>
              <TableCell align="left">
                {PLACEHOLDER && trimString(row.userName, 35)}
              </TableCell>
              <TableCell align="right">
                {PLACEHOLDER && row.marathonUser}
              </TableCell>
              <TableCell align="right">
                {PLACEHOLDER && row.agencyName}
              </TableCell>
              <TableCell align="right">{row.isAdmin ? "YES" : "NO"}</TableCell>
              <TableCell align="right">{row.isAgent ? "YES" : "NO"}</TableCell>
              <TableCell align="right">{PLACEHOLDER && row.agentFor}</TableCell>
              <TableCell align="right">
                {PLACEHOLDER && row.roleNames}
              </TableCell>
              <TableCell align="right">{row.isActive ? "YES" : "NO"}</TableCell>
              <TableCell align="center">
                <Grid item>
                  <IconButton
                    name={`um_edit_${row.userId}`}
                    id={`um_edit_${row.userId}`}
                    size="small"
                    onClick={e => onEditClick(row.userId, e)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Grid item>
                  <IconButton
                    name={`um_profile_${row.userId}`}
                    id={`um_profile_${row.userId}`}
                    size="small"
                    onClick={e => onProfileClick(row.userId, e)}
                  >
                    <ProfileIcon />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

UserManagementList.propTypes = {
  users: PropTypes.array
};

UserManagementList.defaultProps = {
  users: []
};

export default UserManagementList;
